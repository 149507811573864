.Logo {
    display: inline-block;
    img{
        // max-height: 35px;
        @media only screen and (max-width: 991.98px) {
            max-height: 30px;
        }
    }
}

