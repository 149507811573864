@import '../../styles/settings';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/sass/radio-checkbox.scss';

.sidemenu {
  position: fixed;
  transition: all 0.4s ease-in-out;
  max-width: 60px;
  min-width:60px;
  height: 100vh;
  max-height: 100vh;
  z-index: 10;
  top: 0;
  padding-top: 82px;
  overflow: hidden;

  html[dir='ltr'] & {
    // transform: translateX(-100%);
    left: 0;
  }
  html[dir='rtl'] & {
    // transform: translateX(100%);
    right: 0;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }

  @include media-breakpoint-up(lg) {
    &.menuVisible {
      max-width: 60px;
      min-width: 60px;
      overflow-x: hidden;
      html[dir='ltr'] & {
        transform: translateX(1px);
      }
      html[dir='rtl'] & {
        transform: translateX(-1px);
      }
      &.menuExpand {
        ul li a {
          font-size: inherit;
        }
      }
      ul li a {
        font-size: 0;
        &.dropdown-toggle {
          .icon-chevron-down {
            display: none;
          }
        }
      }
    }
  }
  &.menuExpand {
    max-width: 270px;
    min-width: 270px;
    html[dir='ltr'] & {
      transform: translateX(0);
    }
    html[dir='rtl'] & {
      transform: translateX(0);
    }
  }
  ul {
    li {
      position: relative;

      a {
        white-space: nowrap;
        padding: 17px 19px 16px;
        font-size: rem(15) !important;
        color: $gray-700;
        font-weight: 500;
        i {
          min-width: 40px;

        }

        &:hover {
          color: $primary;
          text-decoration: none;
        }

        &.active {
          svg {
            path{
              stroke: $primary;
            }
           
          }
        }
      }
      &.active {
        background-color: #f9fafc;

        .active {
          color: $primary;
        }
      }
    }
  }


}

.menu-exp {
  width: 32px;
  overflow: hidden;
  .active {
    i,
    span {
      display: none !important;
    }
    &::before {
      content: '\e99d';
      font-family: icomoon;
      display: block;
      font-size: 23px;
    }
  }
}

.offer-img {
  background-image: url(../../assets/images/offer-bg.png);
  background-repeat: no-repeat;

  html[dir='rtl'] & {
    transform: scaleX(-1);

    img {
      transform: scaleX(-1);
    }
  }
}
