@import '../../../assets/sass/custom-variables/variables';

.cartItem {
  background-color: $white;
  display: flex;
  padding: rem(15) rem(24);
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #E4E4E4;
  @media (max-width: 575px) {
    padding: rem(16);
  }
  &__items {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: initial;
    }
  }
  &__Title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    &--lg {
      font-size: 16px;
    }
    &--normal {
      font-weight: 400;
    }
  }
  &__product-count {
    font-size: 13px;
    color: #8c8c8c;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__images {
    display: flex;
    @media (max-width: 575px) {
      margin-bottom: 10px;
    }
  }
  &__more {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #8c8c8c;
    padding: 6px;
  }
  &__price-block {
    text-align: right;
    padding: 0 0 0 rem(20);
    font-size: 15px;
    color: #6d6d6d;
    @media (max-width: 991px) {
      padding: 0 rem(16);
    }
    @media (max-width: 575px) {
      padding: 0;
      text-align: left;
    }
  }
  &__promo {
    padding: 0 rem(0);
  }
  &__price {
    font-weight: 700;
    font-size: rem(22);
  }
  &__save {
    font-size: 15px;
    color: #8c8c8c;
    // margin-bottom: 4px;
    &-amount {
      font-weight: 700;
    }
  }
  &__totalprice-block {
    padding-left: rem(20);
    font-size: 14px;
    font-weight: 600;
    @media (max-width: 991px) {
      padding-left: rem(16);
    }
    @media (max-width: 767px) {
      padding-left: 0;
    }
    // @media (max-width: 575px){
    //     padding-top: rem(4);
    // }
  }
  &__promocode {
    padding-left: rem(10);
    font-size: 14px;
    font-weight: 600;
  }
  &__action-block {
    display: flex;
    align-items: center;
    @media (max-width: 575px) {
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      margin-top: rem(10);
    }
  }
  &__figure {
    width: 95px;
    padding-right: 10px;
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  &--free {
    background-color: #fff5d1;
    .bundleOffer__product-count {
      color: #887924;
    }
  }
  &__free {
    color: $primary;
    font-size: 13px;
    font-weight: 600;
    flex: 0 0 100%;
  }
  &--extras {
    justify-content: flex-end;
    @media (max-width: 575px) {
      .cartItem__action-block {
        flex-direction: row;
        justify-content: flex-end;
      }
      .cartItem__price-block {
        padding-right: 15px;
      }
    } 
  }
  &__remove{
    
    transition: all 0.3s ease-in-out;
    color: $gray-600;

    html[dir="ltr"] & {
      transform: translateX(-100%);
    }
    html[dir="rtl"] & {
      transform: translateX(100%);
    }

    @media (min-width: 992px) {
      top: -5px;
      visibility: hidden;
      opacity: 0;

      html[dir="ltr"] & {
        left: -4px;
      }
      html[dir="rtl"] & {
        right: -4px;
      }
    }

    @media (max-width: 991.98px) {
      top: 8px;

      html[dir="ltr"] & {
        right: -10px;
      }
      html[dir="rtl"] & {
        left: -10px;
      }
    }
  }
  @media (min-width: 992px) {
    &:hover{
      .cartItem__remove{
        top: 8px;
        opacity: 1;
        visibility: visible;
        &:hover {
          color: $primary;
        }
      }
    }
  } 
}
