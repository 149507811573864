@import '../../styles/settings';

.numberField {
  display: inline-block;
  position: relative;

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__inner {
    display: flex;
    line-height: 31px;
    max-width: 106px;
    background-color: $white;
  }

  &__input {
    border-color: #cbcbcb;
    border-width: 1px 0 1px 0;
    border-style: solid;
    width: 54px;
    text-align: center;
    font-family: inherit;
    font-weight: 500;

    @media only screen and (max-width: 767.98px) {
      border-width: 0;
      color: $primary;
    }

    &:focus {
      outline: none;
    }
  }

  &__plus,
  &__minus {
    width: 26px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #cbcbcb;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 10px;
      height: 2px;
      background-color: #aaa;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      &:before,
      &:after {
        background-color: $gray-900;
      }
    }
  }

  &__plus {
    background-color: #F5F5F5;
    
    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &__minus:after {
    display: none;
  }

  &__label {
    color: $gray-300;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 5px;
  }

  &--readonly .numberField__inner {
    opacity: 0.75;
    pointer-events: none;
  }

  &--sm {
    .numberField {
      &__input {
        font-size: 14px;
        font-weight: 400;
      }

      &__plus {
        font-size: 18px;
      }

      &__minus {
        font-size: 33px;
      }

      &__label {
        font-size: 10px;
      }
    }
  }

  &--primary {
    .numberField {
      &__inner {
        box-shadow: none;
      }

      &__input {
        border: 0;
        color: $primary;
      }

      &__plus,
      &__minus {
        color: $primary;
        border-color: $primary;
        background-color: $white;
      }
    }
  }
  &__loading{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
  }
}
