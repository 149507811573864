@import "custom-variables/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.form-control-search {
    background-image: url(../../assets/images/search-icon.png);
    background-repeat: no-repeat;
    font-size: 0 !important;
    width: 32px;
    height: 41px;
    background-position: center;
}
.form-control-date {
    background-image: url(../../assets/images/calender.png);
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 0 !important;
}

.filter-search {
    min-width: 190px;

    @include media-breakpoint-down(md) {
        border: 1px solid $input-border-color;
        border-radius: $input-border-radius;
    }
    .form-control::placeholder {
        color: #BABABA;
    }
}
.filter-date {
    min-width: 207px;
    max-width: 207px;
    @include media-breakpoint-down(md) {
        // border: 1px solid $border-color;
        max-width: 100%;
    }
    .form-control::placeholder {
        color: #808080;
    }
}

input{
    &:focus{
        box-shadow: none !important;
    }
}
.dropdown-shadow {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
}
.filter-head{
    background: #F4F7FA;
}
.dropdown-menu {
    transform: scale(0.95);
    transform-origin: top center;
    transition: all 0.2s ease-in-out;
    // opacity: 0;
    // visibility: hidden;
    display: block !important;
    min-width: 241px !important;
    z-index: 999;

    &.in {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
    &.sm{
        min-width: 230px !important;
    }
}

.dropdown-action {
    .dropdown-menu {
        min-width: auto;
    }
}


.dropdown-save {
    .dropdown-menu {
        html[dir="ltr"] & {
            left: inherit;
            right: 0;
        }
    }
}

.tab-block{
    overflow: auto;
    .nav{
        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
        }
        
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(245, 245, 245, 1);
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: $gray-400;
            border-radius: 10px;
        }
    }
    >ul>li{
        margin: 0 rem(15);
        cursor: pointer;
        border-bottom: rem(2) solid transparent;
        line-height: 1;
        white-space: nowrap;
        
        &.active{
            border-color: $primary;
            h4,p{
                color: $primary !important;
            }
        }
        @include media-breakpoint-up(lg) {
            max-width: 150px;
            padding: 13px 0;
            flex: 1;
        }
        @include media-breakpoint-down(md) {
            padding: 16px 0 12px 0;

            p {
                font-size: 14px !important;
            }

            &.active p {
                color: $primary !important;
            }
        }
        h4{
            font-size: 20px;
            font-weight: 700;
            color: $gray-600;
        }
        p{
            font-size: 13px;
            font-weight: 500;
        }
    }
}

.outline.btn-primary{
    border: 1px solid $primary;
    background:transparent;
    color: $primary;
}
.inner-header{
    min-height: 62px;
    .status{
        color: $primary !important;
        background: #BEE1FA;
        padding: 0 5px;
        border-radius: 2px;
    }
}
.tab-table{
    table td {
        padding: 8px 15px !important;
        vertical-align: middle;
    }
}

.list-search{
    @include media-breakpoint-down(md) {
        display: none;
        &.active{
            display: block;
        }
    }
}

.vehicle-tab-listing{
  .tab-block{
    background-color: $white;
    margin-top: 10px;
   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
  }
  .filter-head{
    background-color: $white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-bottom: 10px;
  }
}

.details-wrapper{
    li{
        padding: 12px 0;
       
    }
}
.add-staff-customer-popup{
    @include media-breakpoint-up(lg) {
     max-width: 653px;
    }
    z-index: 1050;
    transition: all 0.3s ease-in-out;
    .textField__input{
        height: 45px;
    }
    .rw-widget-container{
        border: 1px solid #E7EBEF;
        height: 45px;
        border-radius: 12px;
    }
    .flag__dropdown{
        border-radius: 12px;
    }
    .company-logo-upload{
        border: dashed 1px #DCE3E9;
        border-radius: 12px;
        background-color: #f0f3f8;
      }
}