@import '../../styles/settings';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1040;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
  }

  &__frame {
    position: absolute;
    z-index: 1010;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
  }
  &__contentNoPadding {
    position: relative;
    top: 0;
    left: 0;
    background: $white;
    max-height: 95vh;
    max-height: calc(100vh - 12.5rem);
    max-width: 348px;
    // width: 93%;
    overflow: auto;
    @media only screen and (max-width: 991.98px) {
      max-height: calc(100vh - 1.875rem);
    }

    /* spacing as needed */
    // padding: rem(38) rem(33);

    /* let it scroll */
    // overflow: auto;
    &--sm {
      max-width: 409px;
    }
  }
  &__content {
    position: relative;
    top: 0;
    left: 0;
    background: $white;
    max-height: 95vh;
    max-height: calc(100vh - 12.5rem);
    max-width: 585px;
    width: 93%;
    overflow: auto;
    border-radius: 3px;
    @media only screen and (max-width: 991.98px) {
      max-height: calc(100vh - 1.875rem);
    }

    /* spacing as needed */
    padding: rem(38) rem(33);

    /* let it scroll */
    // overflow: auto;
    &--sm {
      max-width: 420px;
    }
  }
  @media (max-width: 767.98px) {
    z-index: 10000;
  }
  &__close {
    position: absolute;
    top: 20px;
    color: #808080;
    cursor: pointer;
    html[dir='ltr'] & {
      right: 17px;
    }
    html[dir='rtl'] & {
      left: 17px;
    }
    i {
      font-size: 13px;
      font-weight: 700;
    }
  }
}
.Modal__content.TextArea__input {
  height: 200px;
}
.Modal__content p {
  color: #262626 !important;
}
