$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$container-max-widths: (
  sm: 1330px,
);

$body-bg: #f0f3f8;
$primary: #023F88;
$secondary: #6d6e70;
$gray-100: #D6E5EF;
$gray-200: #dedede;
$gray-300: #a4a4a4;
$gray-400: #BDBDBD;
$gray-500: #868686;
$gray-600: #808080;
$gray-700: #404040;
$gray-900: #202020;
$border-color: #E4E4E4;
$text-muted: $gray-300;
$white: #fff;

$font-family-sans-serif: 'Figtree',  sans-serif;
$font-family-arabic: 'Almarai', sans-serif;
$font-family-base: 'Figtree',  sans-serif;
$font-size-base: 1rem;
$font-size-lg: 1.0625rem;
$font-size-sm: 0.9375rem;

$headings-color: #202020;

$border-radius-sm : 2px;

$input-border-color: #E7EBEF;
$input-placeholder-color: #BABABA;
$input-padding-x : 0.9375rem;
$input-padding-y: 0.6875rem;
$input-font-size: 14px;

$shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
$shadow-sm: 0px 0px 4px rgba(0, 0, 0, 0.05);

$btn-font-weight: 600;
$btn-font-size: 14px;
$btn-padding-y: 0.438rem;
$btn-padding-x: 0.813rem !important;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$btn-border-radius : 12px;