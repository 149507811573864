[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
  display: none;
}

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
  position: relative;
  cursor: pointer;
  margin: 0;
  display: inline-flex !important;
  align-items: center;
  font-size: rem(14);
  color: $gray-700;
  line-height: 18px;
  min-height: 18px;

  html[dir='ltr'] & {
    padding-left: 30px;
  }

  html[dir='rtl'] & {
    padding-right: 30px;
  }
}

[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 3px;

  html[dir='ltr'] & {
    left: 0;
  }

  html[dir='rtl'] & {
    right: 0;
  }
}

[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
  position: absolute;
  content: '\e943';
  font-family: icomoon;
  top: 0;
  color: $white;
  font-size: 9px;

  html[dir='ltr'] & {
    left: 4px;
  }

  html[dir='rtl'] & {
    right: 4px;
  }
}

[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
}

[type='checkbox']:checked + label:after {
  opacity: 1;
}

[type='checkbox']:not(:checked) + label:before {
  background-color: $white;
  border: 1px solid $border-color;
}

[type='checkbox']:checked + label:before {
  border: 1px solid $primary;
  background-color: $primary;
}

[type='checkbox']:not(:checked) + label.checkbox-sm,
[type='checkbox']:checked + label.checkbox-sm {
  line-height: 15px;
  min-height: 15px;

  html[dir='ltr'] & {
    padding-left: 22px;
  }

  html[dir='rtl'] & {
    padding-right: 22px;
  }
}

[type='checkbox']:not(:checked) + label.checkbox-sm:before,
[type='checkbox']:checked + label.checkbox-sm:before {
  width: 15px;
  height: 15px;
  top: -1px;
}

[type='checkbox']:not(:checked) + label.checkbox-sm:after,
[type='checkbox']:checked + label.checkbox-sm:after {
  font-size: 8px;
}

input[type='radio'] {
  display: none;
}

input[type='radio'] + label {
  position: relative;
  cursor: pointer;
  color: $gray-900;
  margin: 0;

  html[dir='ltr'] & {
    padding-left: 30px;
    margin-right: 10px;
  }

  html[dir='rtl'] & {
    padding-right: 30px;
    margin-left: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    top: 0;
    display: block;
    border: 2px solid #b8b8b8;
    padding: 7px;
    background: $white;
    background-clip: content-box;
    transition: all ease-in-out 0.5s;

    html[dir='ltr'] & {
      left: 0;
    }

    html[dir='rtl'] & {
      right: 0;
    }
  }
}

input[type='radio']:checked + label {
  &:before {
    padding: 4px;
    background: $primary;
    background-clip: content-box;
    border: 2px solid $primary;
  }
}

.switch {
  display: flex;
  align-items: center;
  font-size: rem(15);
  text-transform: uppercase;

  [type='checkbox'] + label {
    padding: 0 !important;

    .switch-handle {
      background: $white;
      border-radius: 33px;
      box-shadow: 0px 0px 4px 2px rgba(203, 203, 203, 0.25);
      border: 1px solid #d2d2d2;
      padding: 2px !important;
      transition: all 0.4s ease;
      position: relative;
      outline: 0;
      display: block;
      width: 71px;
      height: 25px;
      cursor: pointer;
      user-select: none;
      margin: 0 15px;

      &:after {
        position: relative;
        display: block;
        content: '';
        width: 34px;
        height: 100%;
        top: 0;
        border-radius: 33px;
        background: $primary;
        transition: all 0.2s ease;
        opacity: 1;

        html[dir='ltr'] & {
          left: 0;
        }

        html[dir='rtl'] & {
          right: 0;
        }
      }
    }
  }

  [type='checkbox']:not(:checked) + label {
    .switch-before {
      color: $gray-900;
    }

    .switch-after {
      color: $gray-700;
    }
  }

  [type='checkbox']:checked + label {
    .switch-handle:after {
      html[dir='ltr'] & {
        left: 31px;
      }

      html[dir='rtl'] & {
        right: 31px;
      }
    }

    .switch-before {
      color: $gray-700;
    }

    .switch-after {
      color: $gray-900;
    }
  }

  [type='checkbox'] + label {
    &:before,
    &:after {
      display: none;
    }
  }
  &--sm {
    [type='checkbox'] + label {
      .switch-handle {
        width: 35px;
        height: 20px;
        &:after {
          width: 14px;
          background-color: $gray-400;
        }
      }
    }
    [type='checkbox']:checked + label {
      .switch-handle:after {
        background-color: $primary;
        html[dir='ltr'] & {
          left: 14px;
        }

        html[dir='rtl'] & {
          right: 14px;
        }
      }
    }
  }
}
